<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>{{ schedulerTitle }}</h1>
      <div class="btns-container">
        <VasionButton
        id="save-button"
        name="save-button"
        classProp="primary"
        :isDisabled="!isDirty"
        @vasionButtonClicked="save()"
      >
        Save
      </VasionButton>

      <VasionButton
        id="cancel-button"
        class="last-btn"
        name="cancel-button"
        classProp="secondary"
        @vasionButtonClicked="cancel()"
      >
        Cancel
      </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div id="scheduled-report-details">
        <AutomationEngineSharedHeader
          ref="header"
          :nameLabel="'SCHEDULED REPORT NAME'"
          @permissions="showMainSection = false"
          @general-automation="showMainSection = true"
          @dirty="isDirty = true"
        />

        <!-- Build Scheduled Report -->
        <div v-if="showMainSection" id="build-scheduled-report" class="collapsable-section">
          <h2 class="subheader">
            Build Scheduled Report
          </h2>

          <VasionButton
            name="collapse-section"
            class="collapse-section"
            :icon="showBuildScheduledReport ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
            title="Collapse Section"
            @vasionButtonClicked="toggleCollapsableSection('build-scheduled-report')"
          />

          <div v-show="showBuildScheduledReport">
            <div class="row">
              <VasionDropList
                v-slot="slotItem"
                v-model="report"
                class="row-field"
                :dataArray="allReportsList"
                width="396"
                title="REPORT"
                placeholder="Select Report..."
                type="plain-list"
                displayName="name"
                valueName="value"
                :isDisabled="schedulingFromReports || countyBatchScanningEmailReport"
                @input="isDirty = true"
              >
                {{ slotItem.item.name }}
              </VasionDropList>

              <VasionDropList
                v-slot="slotItem"
                v-model="output"
                class="row-field"
                :dataArray="outputList"
                width="396"
                title="OUTPUT TYPE"
                placeholder="Select Output Type..."
                type="plain-list"
                displayName="name"
                valueName="value"
                :isDisabled="countyBatchScanningEmailReport"
                @input="isDirty = true"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </div>

            <div class="row">
              <VasionDropList
                v-if="selectedReport && selectedReport.supportsIndexForm"
                v-slot="slotItem"
                v-model="reportAttributeForm"
                class="row-field"
                :dataArray="selectedReport.dropDownValues"
                :title="`${$formsLabel.toUpperCase()}`"
                type="plain-list"
                valueName="iID"
                width="396"
                displayName="sName"
                @input="isDirty = true"
              >
                {{ slotItem.item.sName }}
              </VasionDropList>

              <VasionInput
                v-if="selectedReport && selectedReport.supportsDateRange"
                id="days-from-today"
                v-model="fromDays"
                class="row-field"
                title="START DAYS FROM 'TODAY'"
                inputType="top-white"
                name="days-from-today"
                placeholder="ENTER DAYS FROM TODAY..."
                :width="'190'"
                type="number"
                @input="isDirty = true"
              />

              <VasionInput
                v-if="selectedReport && selectedReport.supportsDateRange"
                id="days-after-today"
                v-model="throughDays"
                class="row-field"
                title="THROUGH DAYS FROM 'TODAY'"
                inputType="top-white"
                name="days-after-today"
                placeholder="Enter Days after Today..."
                :width="'190'"
                type="number"
                @input="isDirty = true"
              />

              <!-- Not sure if this is needed or not
              <VasionDropList
                v-if='selectedReport && selectedReport.supportsDropDown'
                #default="slotItem"
                :dataArray="selectedReport.dropDownValues"
                :title="selectedReport.sDropDownLabelText"
                type="plain-list"
                valueName="sName"
                displayName="sName"
                v-model="dropdownSelection">
                  {{slotItem.item.sName}}
              </VasionDropList>
              -->
            </div>

            <div class="row">
              <VasionCheckbox
                id="send-to-external-folder"
                name="send-to-external-folder"
                class="scheduler-checkbox"
                :checked="sendToExternalFolder"
                :isDisabled="countyBatchScanningEmailReport"
                @change="toggleCheckbox('sendToExternalFolder');"
              >
                Send to External Folder
              </VasionCheckbox>
            </div>

            <div v-if="sendToExternalFolder" class="row">
              <VasionInput
                id="external-folder"
                v-model="externalFolder"
                class="row-field"
                title="EXTERNAL FOLDER"
                inputType="top-white"
                name="external-folder"
                placeholder="Enter External Path..."
                :width="'396'"
                @input="isDirty = true"
              />
            </div>

            <div class="row">
              <VasionCheckbox
                id="send-to-email-address"
                name="send-to-email-address"
                class="scheduler-checkbox"
                :checked="sendToEmailAddress"
                :isDisabled="countyBatchScanningEmailReport"
                @change="toggleCheckbox('sendToEmailAddress');"
              >
                Send to Email Address
              </VasionCheckbox>
            </div>

            <div v-if="sendToEmailAddress" class="row">
              <VasionInput
                id="email-address"
                v-model="emailAddress"
                class="row-field"
                title="EMAIL ADDRESS"
                inputType="top-white"
                name="email-address"
                placeholder="Enter Email Address..."
                :width="'396'"
                type="email"
                @input="isDirty = true"
              />
            </div>

            <div class="row">
              <VasionCheckbox
                id="send-to-vasion"
                name="send-to-vasion"
                class="scheduler-checkbox"
                :checked="sendToVasion"
                :isDisabled="countyBatchScanningEmailReport"
                @change="toggleCheckbox('sendToVasion');"
              >
                Send to Vasion
              </VasionCheckbox>
            </div>

            <div v-if="sendToVasion" class="row">
              <VasionInput
                id="store-in-folder"
                v-model="storeInFolder"
                class="row-field browse-input"
                title="STORE IN FOLDER"
                inputType="top-white"
                name="store-in-folder"
                placeholder="Select Folder..."
                :width="'296'"
                @input="isDirty = true"
              />
              <VasionButton
                class="browse-button"
                :classProp="'primary'"
                @vasionButtonClicked="toggleBrowseFolderDialog(); activeBrowse = 'storeInFolder'"
              >
                Browse
              </VasionButton>

              <VasionDropList
                v-slot="slotItem"
                v-model="storageAttributeForm"
                class="row-field"
                :dataArray="attributeFormList"
                width="396"
                :title="`STORAGE ${$formsLabel.toUpperCase()}`"
                :placeholder="`Select ${$formsLabel}...`"
                type="plain-list"
                displayName="name"
                valueName="value"
                @input="isDirty = true"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </div>

            <div v-if="sendToVasion" class="row">
              <VasionDropList
                v-if="storageAttributeForm && storageAttributeForm.value > 0"
                v-slot="slotItem"
                v-model="attributeFormName"
                class="row-field"
                :dataArray="attributeFieldList"
                width="396"
                :title="`${$formsLabel.toUpperCase()} NAME`"
                :placeholder="`Select Field for ${$formsLabel} Name...`"
                type="plain-list"
                displayName="name"
                valueName="value"
                @input="isDirty = true"
              >
                {{ slotItem.item.name }}
              </VasionDropList>

              <VasionDropList
                v-if="storageAttributeForm && storageAttributeForm.value > 0"
                v-slot="slotItem"
                v-model="attributeReportName"
                class="row-field"
                :dataArray="attributeFieldList"
                width="396"
                title="REPORT NAME"
                placeholder="Select Field for Report Name..."
                type="plain-list"
                displayName="name"
                valueName="value"
                @input="isDirty = true"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </div>

            <div v-if="sendToVasion" class="row">
              <VasionDropList
                v-if="storageAttributeForm && storageAttributeForm.value > 0"
                v-slot="slotItem"
                v-model="attributeFromDate"
                class="row-field"
                :dataArray="attributeFieldList"
                width="396"
                title="FROM DATE"
                placeholder="Select Field for From Date..."
                type="plain-list"
                displayName="name"
                valueName="value"
                @input="isDirty = true"
              >
                {{ slotItem.item.name }}
              </VasionDropList>

              <VasionDropList
                v-if="storageAttributeForm && storageAttributeForm.value > 0"
                v-slot="slotItem"
                v-model="attributeThroughDate"
                class="row-field"
                :dataArray="attributeFieldList"
                width="396"
                title="THROUGH DATE"
                placeholder="Select Field for Through Date..."
                type="plain-list"
                displayName="name"
                valueName="value"
                @input="isDirty = true"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </div>
            <div v-if="featureFlagCountyBatchScanning">
              <div 
                class="row"
              >
                <VasionCheckbox
                  id="send-to-vasion"
                  name="send-to-vasion"
                  class="scheduler-checkbox"
                  :checked="countyBatchScanningEmailReport"
                  @change="toggleCheckbox('countyBatchScanningEmailReport');"
                >
                  County Batch Scanning Email Report
                </VasionCheckbox>
              </div>

              <div v-if="countyBatchScanningEmailReport" class="row">
                <VasionInput
                  id="county-process-folder"
                  v-model="batchScanningProcessFolder"
                  class="row-field browse-input"
                  title="PROCESS FOLDER"
                  inputType="top-white"
                  name="county-process-folder"
                  placeholder="Select Folder..."
                  :isDisabled="true"
                  :width="'296'"
                  @input="isDirty = true"
                />
                <VasionButton
                  class="browse-button"
                  :classProp="'primary'"
                  @vasionButtonClicked="toggleBrowseFolderDialog(); activeBrowse = 'batchScanningProcessFolder'"
                >
                  Browse
                </VasionButton>

                <VasionDropList
                  v-slot="slotItem"
                  v-model="batchScanningObjectToUse"
                  class="row-field"
                  :dataArray="attributeFormList"
                  width="396"
                  :title="`OBJECT TO USE`"
                  :placeholder="`Select Object...`"
                  type="plain-list"
                  displayName="name"
                  valueName="value"
                  @input="isDirty = true"
                >
                  {{ slotItem.item.name }}
                </VasionDropList>
              </div>

              <div v-if="countyBatchScanningEmailReport && batchScanningObjectToUse">
                <div class="row">
                  <VasionDropList
                    v-slot="slotItem"
                    v-model="batchScanningEmailTo"
                    class="row-field"
                    :dataArray="countyObjectFieldsList"
                    width="396"
                    :title="`EMAIL TO`"
                    :placeholder="`Select Field...`"
                    type="plain-list"
                    displayName="name"
                    valueName="fieldID"
                    @input="isDirty = true"
                  >
                    {{ slotItem.item.name }}
                  </VasionDropList>

                  <VasionDropList
                    v-slot="slotItem"
                    v-model="batchScanningReportSent"
                    class="row-field"
                    :dataArray="countyObjectFieldsList"
                    width="396"
                    :title="`REPORT SENT`"
                    :placeholder="`Select Field...`"
                    type="plain-list"
                    displayName="name"
                    valueName="fieldID"
                    @input="isDirty = true"
                  >
                    {{ slotItem.item.name }}
                  </VasionDropList>

                  <VasionDropList
                    v-slot="slotItem"
                    v-model="batchScanningBatchID"
                    class="row-field"
                    :dataArray="countyObjectFieldsList"
                    width="396"
                    :title="`BATCH ID`"
                    :placeholder="`Select Field...`"
                    type="plain-list"
                    displayName="name"
                    valueName="fieldID"
                    @input="isDirty = true"
                  >
                    {{ slotItem.item.name }}
                  </VasionDropList>
                </div>
              </div>

              <div v-if="countyBatchScanningEmailReport" class="row">
                <VasionDropList
                  v-slot="slotItem"
                  v-model="batchScanningEmailTemplate"
                  class="row-field"
                  :dataArray="emailTemplateList"
                  width="396"
                  :title="`EMAIL TEMPLATE`"
                  :placeholder="`Select Template...`"
                  type="plain-list"
                  displayName="name"
                  valueName="value"
                  @input="isDirty = true"
                >
                  {{ slotItem.item.name }}
                </VasionDropList>

                <VasionInput
                  id="county-move-to-folder"
                  v-model="batchScanningMoveToFolder"
                  class="row-field browse-input"
                  title="MOVE TO FOLDER"
                  inputType="top-white"
                  name="county-move-to-folder"
                  placeholder="Select Folder..."
                  :isDisabled="true"
                  :width="'296'"
                  @input="isDirty = true"
                />
                <VasionButton
                  class="browse-button"
                  :classProp="'primary'"
                  @vasionButtonClicked="toggleBrowseFolderDialog(); activeBrowse = 'batchScanningMoveToFolder'"
                >
                  Browse
                </VasionButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <md-dialog :md-active.sync="showBrowseFoldersDialog" :md-click-outside-to-close="false">
      <VasionFolderSelect
        okButtonText="OK"
        :title="browseModalTitle"
        @cancelButtonClick="toggleBrowseFolderDialog()"
        @okButtonClick="folderDialogOK"
      />
    </md-dialog>

    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarText"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
/* eslint-disable valid-typeof */
/* eslint-disable no-mixed-operators */

import AutomationEngineSharedHeader from '@/views/admin/automationEngine/AutomationEngineSharedHeader.vue';

export default {
  name: 'EditScheduledReport',
  components: {
    AutomationEngineSharedHeader,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.name
      vm.toRoute = to.name
      vm.prevRoutePath = from.path
      vm.prevRouteParams = from.params
    })
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      this.$store.dispatch('reporting/setScheduledReportName', '')
      next()
    }
  },
  // This protects the route from being changed if you click on a different scheduled report in the left-hand menu.
  // It appears it is called twice when navigating away, so the showLeaveDialog value is checked.
  beforeRouteUpdate(to, from, next) {
    if (this.isDirty && (!this.routeTo || this.showLeaveDialog)) {
      this.routeTo = to
      this.showLeaveDialog = true
      next(false)
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      activeBrowse: '',
      allReportsList: [],
      attributeFieldList: [],
      attributeFormName: '',
      attributeFromDate: '',
      attributeReportName: '',
      attributeRunDate: '',
      attributeThroughDate: '',
      batchScanningBatchID: undefined,
      batchScanningEmailTemplate: {},
      batchScanningEmailTo: undefined,
      batchScanningMoveToFolder: '',
      batchScanningMoveToFolderId: 0,
      batchScanningObjectToUse: {},
      batchScanningProcessFolder: '',
      batchScanningProcessFolderId: 0,
      batchScanningReportSent: undefined,
      countyBatchScanningEmailReport: false,
      dropdownSelection: '',
      emailAddress: '',
      externalFolder: '',
      featureFlagCountyBatchScanning: false,
      fromDays: 0,
      isDirty: false,
      countyObjectFields: [],
      output: '',
      prevRoute: null,
      prevRouteParams: null,
      prevRoutePath: null,
      report: '',
      reportAttributeForm: '',
      schedulerId: 0,
      schedulerName: '',
      schedulerTitle: '',
      schedulingFromReports: false,
      selectedReport: {},
      sendToEmailAddress: false,
      sendToExternalFolder: false,
      sendToVasion: false,
      showBrowseFoldersDialog: false,
      showBuildScheduledReport: false,
      showLeaveDialog: false,
      showMainSection: true,
      showSnackbar: false,
      snackbarImage: false,
      snackbarTitle: '',
      snackbarText: '',
      storageAttributeForm: '',
      storeInFolderId: 0,
      storeInFolder: '',
      storeInVasion: false,
      throughDays: 0,
      toRoute: null,
    }
  },
  computed: {
    activeScheduler() { return this.$store.state.automationEngine.activeScheduler },
    attributeFormList() {
      return this.$store.state.attributeForm.forms.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
    },
    browseModalTitle() {
      if (this.activeBrowse === 'batchScanningMoveToFolder') return 'Select Move To Folder'
      if (this.activeBrowse === 'batchScanningProcessFolder') return 'Select Process Folder'
      if (this.activeBrowse === 'storeInFolder') return 'Select Store In Folder'
      return ''
    },
    emailTemplateList() {
      return this.$store.state.common.emailTemplates.map((field) => {
        return {
          name: field.sName,
          value: field.iID,
        }
      })
    },
    genericReportForm() { return this.$store.state.reporting.genericReportForm },
    indexFields() {
      return this.$store.state.common.indexFields.map((field) => {
        return {
          name: field.name,
          value: field.fieldID,
        }
      })
    },
    countyObjectFieldsList() {
      return this.countyObjectFields.map((field) => {
        return {
          name: field.name,
          fieldID: field.fieldID,
        }
      })
    },
    outputList() { return [{ name: 'PDF', value: 1 }, { name: 'CSV', value: 2 }] },
    selectedCustomReport() { return this.$store.state.reporting.selectedRunCustomReport },
  },
  watch: {
    batchScanningObjectToUse: async function () {
      this.countyObjectFields = await this.$store.dispatch('common/getIndexFieldsForForm', this.batchScanningObjectToUse.value)
      this.batchScanningBatchID = undefined
      this.batchScanningEmailTo = undefined
      this.batchScanningReportSent = undefined
    },
    countyBatchScanningEmailReport: function () {
      this.sendToEmailAddress = false
      this.sendToExternalFolder = false
      this.sendToVasion = false
    },
    emailTemplateList: function () {
      if (this.batchScanningEmailTemplate?.value){
        this.batchScanningEmailTemplate.name = this.emailTemplateList.find((element) => element.value === this.batchScanningEmailTemplate.value).name
      }
    },
    selectedReport: function () {
      if (this.prevRoute && this.prevRoute === 'TheGenericReport') {
        this.reportAttributeForm = this.selectedReport && this.selectedReport.supportsIndexForm && this.selectedReport.dropDownValues
          ? this.selectedReport.dropDownValues.find(v => v.iID === this.genericReportForm.attributeForm.iID) || ''
          : ''
      } else {
        this.reportAttributeForm = this.selectedReport && this.selectedReport.supportsIndexForm && this.selectedReport.dropDownValues
            ? this.selectedReport.dropDownValues.find(v => v.iID === this.activeScheduler.runAttributeFormID) || ''
            : ''
      }
    },
    storageAttributeForm: async function () {
      if (this.storageAttributeForm && this.storageAttributeForm.value > 0) {
        this.attributeFieldList = await this.$store.dispatch('common/getIndexFieldsForForm', this.storageAttributeForm.value)
      }
    },
    report: function () {
      if (this.report && this.report.value !== '') {
        this.selectedReport = this.allReportsList.find(r => r.value === this.report.value)
      }
    },
  },
  async created() {
    await this.$store.dispatch('automationEngine/clearActiveScheduler')
    const reportsAutomationList = await this.$store.dispatch('reporting/getAllReportsForAutomation')
    this.allReportsList = this.getAllReportsForAutomation(reportsAutomationList)
    await this.$store.dispatch('attributeForm/getForms')
    if (this.prevRoutePath && this.prevRoutePath.includes('/reporting')) {
      this.setReportingValues()
      this.schedulingFromReports = !!this.report
    }
    if (this.prevRoute === null) {
      this.handleRefresh()
    }
    if (this.$route.params.schedulerId && parseInt(this.$route.params.schedulerId, 10) > 0) {
      await this.$store.dispatch('automationEngine/getScheduledReportDetails', parseInt(this.$route.params.schedulerId, 10))
      if (this.activeScheduler) {
        if (this.activeScheduler?.storeAttributeFormID > 0) {
          this.attributeFieldList = await this.$store.dispatch('common/getIndexFieldsForForm', this.activeScheduler.storeAttributeFormID)
        }
        this.setSchedulerValues()
      }
    }
    this.setHeaderDetails(this.schedulerId, this.activeScheduler?.runDetails)

    this.schedulerTitle = this.schedulerId && this.schedulerId > 0 && this.activeScheduler.runDetails ? this.activeScheduler.runDetails.name : 'Untitled Scheduled Report'
    await this.$store.dispatch('common/getEmailTemplates')
    this.featureFlagCountyBatchScanning = this.$store.state.systemSettings.featureFlags.barcodeBatchScanning
    if (this.batchScanningObjectToUse.value) {
      this.countyObjectFields = await this.$store.dispatch('common/getIndexFieldsForForm', this.batchScanningObjectToUse.value)
      this.batchScanningBatchID.name = this.countyObjectFields.find((element) => element.fieldID === this.batchScanningBatchID.fieldID).name
      this.batchScanningEmailTo.name = this.countyObjectFields.find((element) => element.fieldID === this.batchScanningEmailTo.fieldID).name
      this.batchScanningReportSent.name = this.countyObjectFields.find((element) => element.fieldID === this.batchScanningReportSent.fieldID).name
    }
  },
  methods: {
    cancel() {
      this.$router.push(this.prevRouteParams.reportType !== null
        ? { name: this.prevRoute, params: { reportType: this.prevRouteParams.reportType, reportId: this.prevRouteParams.reportId } }
        : { name: this.prevRoute })
    },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    clickedOutsideLeave() { this.routeTo = null },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$store.dispatch('reporting/setScheduledReportName', '')
      this.$router.push({ path: this.routeTo.path })
    },
    folderDialogOK(payload) {
      this.setField(this.activeBrowse + 'Id', payload.SelectedFolderID)
      this.setField(this.activeBrowse, payload.SelectedFolderName)

      this.toggleBrowseFolderDialog()
    },
    getAllReportsForAutomation(reportsArray) {
      const allReports = reportsArray.map((r) => {
        return {
          name: r.sName,
          value: r.sName,
          type: r.sReportType,
          supportsIndexForm: r.bSupportsIndexForm,
          supportsDateRange: r.bSupportsDateRange,
          supportsDropDown: r.bSupportsDropDown,
          dropDownValues: r.oDropDownValues,
        }
      })

      return allReports.sort()
    },
    handleRefresh() {
      switch (this.toRoute) {
        case 'EditReportScheduledReporting':
          this.prevRoute = 'ReportingScheduledReports'
          break;
        case 'EditScheduledReporting':
          this.prevRoute = 'ScheduledReports'
          break;
        default:
          this.prevRoute = 'TheWorkspaceHome'
          break
      }
    },
    hideSnackbar() { this.showSnackbar = false },
    async save() {
      const { header } = this.$refs
      if (await this.validate(header)) {
        const payload = {
          runDetails: await header.getValues(),
          exportFileType: this.output && this.output !== '' ? this.output.name : 'PDF',
          reportName: this.report && this.report !== '' ? this.report.value : '',
          runAttributeFormID: this.reportAttributeForm && this.reportAttributeForm !== '' ? this.reportAttributeForm.iID : 0,
          runFromDays: this.fromDays && this.fromDays !== 0 ? this.fromDays : 0,
          runThroughDays: this.throughDays && this.throughDays !== 0 ? this.throughDays : 0,
          sendToWindowsFolderPath: this.externalFolder,
          sendToEmail: this.emailAddress,
          storeFolderID: this.storeInFolderId,
          storeFolderName: this.storeInFolder,
          storeAttributeFormID: this.storageAttributeForm && this.storageAttributeForm !== '' ? this.storageAttributeForm.value : 0,
          reportNameFieldName: this.attributeReportName && this.attributeReportName !== '' ? this.attributeReportName.value : '',
          runDateFieldName: '',
          fromDateFieldName: this.attributeFromDate && this.attributeFromDate !== '' ? this.attributeFromDate.value : '',
          throughDateFieldName: this.attributeThroughDate && this.attributeThroughDate !== '' ? this.attributeThroughDate.value : '',
          indexFormFieldName: this.attributeFormName && this.attributeFormName !== '' ? this.attributeFormName.value : '',
          shouldSendToLocalFolder: this.sendToExternalFolder,
          shouldSendToEmail: this.sendToEmailAddress,
          shouldSendToVasion: this.sendToVasion,
          useBatchReport: this.countyBatchScanningEmailReport,
          batchReportFolderId: this.batchScanningProcessFolderId,
          batchReportObjectId: this.batchScanningObjectToUse && this.batchScanningObjectToUse !== '' ? this.batchScanningObjectToUse.value : 0,
          batchReportEmailToFieldId: this.batchScanningEmailTo ? this.batchScanningEmailTo.fieldID : null,
          batchReportReportSentFieldId: this.batchScanningReportSent ? this.batchScanningReportSent.fieldID : null,
          batchReportBatchIdFieldId: this.batchScanningBatchID ? this.batchScanningBatchID.fieldID : null,
          batchReportEmailTemplateId: this.batchScanningEmailTemplate.value ? this.batchScanningEmailTemplate.value : null,
          batchReportMoveToFolderId: this.batchScanningMoveToFolderId,
        }

        if (!await this.$store.dispatch('automationEngine/saveScheduledReport', payload)) {
          const errorText = 'There was an error saving the scheduled report.'
          this.snackbarTitle = 'ERROR'
          this.snackbarText = errorText
          this.showSnackbar = true
          this.snackbarImage = false
          console.warn(errorText)
        } else {
          this.isDirty = false

          this.snackbarTitle = 'SUCCESS'
          this.snackbarText = 'The scheduled report saved successfully'
          this.showSnackbar = true
          this.snackbarImage = true

          setTimeout(() => {
            this.$router.push(this.prevRouteParams.reportType !== null ? { name: this.prevRoute, params: { reportType: this.prevRouteParams.reportType, reportId: this.prevRouteParams.reportId } } : { name: this.prevRoute })
          }, 1500)
        }
      }
    },
    setField(field, value) {
      this[field] = value
    },
    setHeaderDetails(id, details) {
      const { header } = this.$refs
      if (id > 0) {
        header.setValues(details)
      } else {
        header.setValues()
      }
    },
    setReportingValues() {
      if (this.prevRoute === 'TheCustomReport') {
        this.report = this.allReportsList.find(r => r.name === this.selectedCustomReport.sName)
      } else if (this.prevRoute === 'TheGenericReport') {
        this.report = this.allReportsList.find(r => r.name === this.genericReportForm.reportType.sName)
        const currentDate = new Date()
        let timeDiff = this.genericReportForm.fromDate - currentDate
        this.fromDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))
        timeDiff = this.genericReportForm.toDate - currentDate
        this.throughDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))
      } else {
        this.report = this.allReportsList.find(r => r.name === this.$store.state.reporting.scheduledReport)
      }
    },
    setSchedulerValues() {
      this.schedulerId = this.activeScheduler.runDetails.schedulerID
      this.output = this.outputList.find(o => o.name === this.activeScheduler.exportFileType) || ''
      this.report = this.allReportsList.find(r => r.name === this.activeScheduler.reportName) || ''
      this.reportAttributeForm = this.selectedReport && this.selectedReport.supportsIndexForm && this.selectedReport.dropDownValues
          ? this.selectedReport.dropDownValues.find(v => v.iID === this.activeScheduler.runAttributeFormID) || ''
          : ''
      this.fromDays = this.activeScheduler.runFromDays
      this.throughDays = this.activeScheduler.runThroughDays
      this.externalFolder = this.activeScheduler.sendToWindowsFolderPath
      this.emailAddress = this.activeScheduler.sendToEmail
      this.storeInFolderId = this.activeScheduler.storeFolderID
      this.storeInFolder = this.activeScheduler.storeFolderName
      this.storageAttributeForm = this.attributeFormList.find(f => f.value === this.activeScheduler.storeAttributeFormID) || ''
      this.attributeReportName = this.attributeFieldList && this.attributeFieldList.length > 0 ? this.attributeFieldList.find(f => f.value === this.activeScheduler.reportNameFieldName) || '' : ''
      this.attributeFromDate = this.attributeFieldList && this.attributeFieldList.length > 0 ? this.attributeFieldList.find(f => f.value === this.activeScheduler.fromDateFieldName) || '' : ''
      this.attributeThroughDate = this.attributeFieldList && this.attributeFieldList.length > 0 ? this.attributeFieldList.find(f => f.value === this.activeScheduler.throughDateFieldName) || '' : ''
      this.attributeFormName = this.attributeFieldList && this.attributeFieldList.length > 0 ? this.attributeFieldList.find(f => f.value === this.activeScheduler.indexFormFieldName) || '' : ''
      this.sendToExternalFolder = this.activeScheduler.shouldSendToLocalFolder
      this.sendToEmailAddress = this.activeScheduler.shouldSendToEmail
      this.sendToVasion = this.activeScheduler.shouldSendToVasion
      this.countyBatchScanningEmailReport = this.activeScheduler.useBatchReport
      if (this.countyBatchScanningEmailReport) {
        this.batchScanningBatchID = {}
        this.batchScanningEmailTo = {}
        this.batchScanningReportSent = {}
        this.batchScanningProcessFolder = this.activeScheduler.batchReportFolderName
        this.batchScanningProcessFolderId = this.activeScheduler.batchReportFolderId
        this.batchScanningObjectToUse.value = this.activeScheduler.batchReportObjectId
        this.batchScanningEmailTo.fieldID = this.activeScheduler.batchReportEmailToFieldId
        this.batchScanningReportSent.fieldID = this.activeScheduler.batchReportReportSentFieldId
        this.batchScanningBatchID.fieldID = this.activeScheduler.batchReportBatchIdFieldId
        this.batchScanningEmailTemplate.value = this.activeScheduler.batchReportEmailTemplateId
        this.batchScanningMoveToFolder = this.activeScheduler.batchReportMoveToFolderName
        this.batchScanningMoveToFolderId = this.activeScheduler.batchReportMoveToFolderId
      }
    },
    toggleBrowseFolderDialog() { this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog },
    toggleCheckbox(id) {
      this[id] = !this[id]
      this.isDirty = true
    },
    toggleCollapsableSection(section) {
      switch (section) {
        case 'build-scheduled-report':
          this.showBuildScheduledReport = !this.showBuildScheduledReport
          break;
        default:
          this.showBuildScheduledReport = false
          break;
      }
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    async validate(header) {
      let error = false
      let errorText = ''
      await header.validate()
      if (header.errors !== '') {
        error = true
        errorText = `${errorText} ${header.errors}`
      }

      if (header.name.trim() === '') {
        error = true
        errorText = ' - Scheduled Report Name cannot only include spaces.\n'
      }

      if (!this.countyBatchScanningEmailReport) {
        if (this.sendToExternalFolder && this.externalFolder === '') {
          errorText = `${errorText} - Please provide a value for "External Folder," since "Send to External Folder" is checked.\n`
          error = true
        }
  
        if (!this.report || this.report === '' || !this.report.value || this.report.value === '') {
          errorText = `${errorText} - Please provide a value for "Report".\n`
          error = true
        }
  
        if (!this.output || this.output === '') {
          errorText = `${errorText} - Please provide an "Output Type."\n`
          error = true
        }
  
        if (this.sendToEmailAddress && this.emailAddress === '') {
          errorText = `${errorText} - Please provide a value for "Email Address," since "Send to Email Address" is checked.\n`
          error = true
        }
  
        if (this.storeInVasion && (!this.storeInFolderId || this.storeInFolderId === '' || this.storeInFolderId === 0)) {
          errorText = `${errorText} - Please provide a value for "Store in Folder," since "Send to Vasion" is checked.\n`
          error = true
        }
  
        if (this.selectedReport && this.selectedReport.supportsDateRange && this.fromDays === null) {
          errorText = `${errorText} - Please provide a value for "START DAYS FROM 'TODAY'".\n`
          error = true
        }
  
        if (this.selectedReport && this.selectedReport.supportsDateRange && this.throughDays === null) {
          errorText = `${errorText} - Please provide a value for "THROUGH DAYS FROM 'TODAY'".\n`
          error = true
        }
      } else {
        if (!this.batchScanningProcessFolderId) {
          errorText = `${errorText} - Please provide a folder under "PROCESS FOLDER".\n`
          error = true
        }
        if (!this.batchScanningObjectToUse.value) {
          errorText = `${errorText} - Please provide an object under "OBJECT TO USE".\n`
          error = true
        }
        if (!this.batchScanningEmailTo) {
          errorText = `${errorText} - Please select a field under "EMAIL TO".\n`
          error = true
        }
        if (!this.batchScanningReportSent) {
          errorText = `${errorText} - Please select a field under "REPORT SENT".\n`
          error = true
        }
        if (!this.batchScanningBatchID) {
          errorText = `${errorText} - Please select a field under "BATCH ID".\n`
          error = true
        }
        if (!this.batchScanningEmailTemplate.value) {
          errorText = `${errorText} - Please provide an email template under "EMAIL TEMPLATE".\n`
          error = true
        }
        if (!this.batchScanningMoveToFolderId) {
          errorText = `${errorText} - Please provide a folder under "MOVE TO FOLDER".\n`
          error = true
        }
      }

      if (error) {
        this.snackbarTitle = 'ERROR'
        this.snackbarText = errorText
        this.showSnackbar = true
        this.snackbarImage = false
        console.warn(errorText)
        return false
      }

      return true
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  #scheduled-report-details {
    width: 100%;
    .row {
      width: 100%;
      margin: 13px 0;
      display: flex;

      .section-title {
        @include SECTION-HEADER;
        color: $grey-400;
        display: block;
        margin: 5px 0;
      }

      .subheader {
        @include SubHeadline;
      }

      .row-field {
        margin: 0 14px 5px 0;

        &.checkbox {
          width: 390px;
        }
      }

      .browse-input {
        margin-right: 5px;
      }

      .vasion-button {
        &.browse-button {
          align-self: end;
          button {
            margin-top: 22px;
            margin-left: 0;
            margin-right: 13px;
          }
        }
      }

      .scheduler-checkbox {
        width: 408px;
      }
    }

    .collapsable-section {
      float: left;
      margin-top: 20px;
      width: 100%;

      .subheader {
        @include SubHeadline;
        width: 250px;
        display: block;
        float: left;
        margin-top: 5px;
      }

      .subtitle {
        @include Subtitle;
      }
    }
  }
</style>
